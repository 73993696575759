import React, { lazy, Suspense, useEffect } from "react";
import Loading from "./ShareModule/Loading";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import PrivateRoute from "./ShareModule/PrivateRouter";
import Header from "./ShareModule/Header";
import { useTranslation } from "react-i18next";


const HomePage = lazy(() => import("./Component/Home/Home"));
const AboutUs = lazy(() => import("./Component/AboutUs/Aboutus"));
const ContactPage = lazy(() => import("./Component/Contact/Contact"));
const CareerPage = lazy(() => import("./Component/Career/Carrer"));
const JobDescription = lazy(() => import("./Component/Career/JobDescription"));
const CareerForm = lazy(() =>
  import("./Component/Career/Career Form/CareerForm.js")
);
const BankingServices = lazy(() =>
  import("./Component/Services/BankingServices")
);
const PaymentServices = lazy(() =>
  import("./Component/Services/PaymentServices.js")
);
const UtilityPayment = lazy(() =>
  import("./Component/Services/UtilityPayment.js")
);
const Devices = lazy(() =>
  import("./Component/Services/Devices.js")
);
const TravelService = lazy(() =>
  import("./Component/Services/TravelService.js")
);
const PartnerService = lazy(() =>
  import("./Component/Services/PartnerService.js")
);
const JoinAsAgent = lazy(() => import("./Component/Home/Joinus/JoinAsAgent"));
const JoinAsDistributor = lazy(() =>
  import("./Component/Home/Joinus/JoinAsDistributor")
);
const JoinAsSuperDistributor = lazy(() =>
  import("./Component/Home/Joinus/JoinAsSuperDistributor")
);
const MissionAndVision = lazy(() =>
  import("./Component/AboutUs/MissioAndVision")
);
const Management = lazy(() => import("./Component/AboutUs/Management"));
const PrivacyPolicy = lazy(() => import("./ShareModule/PrivacyPolicy"));
const TermsAndConditions = lazy(() =>
  import("./ShareModule/TermsAndConditions")
);
const NotFoundPage = lazy(() => import("./ShareModule/Error404.js")); // Lazy load your 404 component

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem("Language")) {
      i18n.changeLanguage(localStorage.getItem("Language"));
    }
    AOS.init();
    window.addEventListener("load", () => {
      AOS.refresh();
    });
  }, []);

  return (
    <div className="App">
      <Router>
        <Header />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <HomePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/AboutUs"
              element={
                <PrivateRoute>
                  <AboutUs />
                </PrivateRoute>
              }
            />
            <Route
              path="/Contact"
              element={
                <PrivateRoute>
                  <ContactPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/Carrer"
              element={
                <PrivateRoute>
                  <CareerPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/JobDescription"
              element={
                <PrivateRoute>
                  <JobDescription />
                </PrivateRoute>
              }
            />
            <Route
              path="/CareerForm"
              element={
                <PrivateRoute>
                  <CareerForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/BankingServices"
              element={
                <PrivateRoute>
                  <BankingServices />
                </PrivateRoute>
              }
            />
            <Route
              path="/PaymentServices"
              element={
                <PrivateRoute>
                  <PaymentServices />
                </PrivateRoute>
              }
            />
            <Route
              path="/utility.html"
              element={
                <PrivateRoute>
                  <UtilityPayment />
                </PrivateRoute>
              }
            />
            <Route
              path="/devices"
              element={
                <PrivateRoute>
                  <Devices />
                </PrivateRoute>
              }
            />
            <Route
              path="/travelService"
              element={
                <PrivateRoute>
                  <TravelService />
                </PrivateRoute>
              }
            />
            <Route
              path="/partnerService"
              element={
                <PrivateRoute>
                  <PartnerService />
                </PrivateRoute>
              }
            />
            <Route
              path="/joinAsAgent"
              element={
                <PrivateRoute>
                  <JoinAsAgent />
                </PrivateRoute>
              }
            />
            <Route
              path="/joinAsDistributor"
              element={
                <PrivateRoute>
                  <JoinAsDistributor />
                </PrivateRoute>
              }
            />
            <Route
              path="/joinAsSuperDistributor"
              element={
                <PrivateRoute>
                  <JoinAsSuperDistributor />
                </PrivateRoute>
              }
            />
            <Route
              path="/MissionAndVision"
              element={
                <PrivateRoute>
                  <MissionAndVision />
                </PrivateRoute>
              }
            />
            <Route
              path="/Management"
              element={
                <PrivateRoute>
                  <Management />
                </PrivateRoute>
              }
            />
            <Route
              path="/privacy_policy.html"
              element={
                <PrivateRoute>
                  <PrivacyPolicy />
                </PrivateRoute>
              }
            />
            <Route
              path="/termsAndConditions"
              element={
                <PrivateRoute>
                  <TermsAndConditions />
                </PrivateRoute>
              }
            />
            {/* Wildcard route for 404 */}
            <Route
              path="*"
              element={<NotFoundPage />} // Display a custom 404 component
            />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
